import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { FormattedMessage } from 'react-intl'
import defaultModalActions from "@store/actions/defaultModal"

const LessonModdal = ({ openURL, date }) => {

  const { closeDefaultModal } = defaultModalActions()

  const onClose = () => {
    closeDefaultModal()
  }

  const onOpenLink = () => {
    const isIshkola = /ishkolalk/.test(window.navigator.userAgent)
    window.open(openURL, isIshkola ? '_self' : '_blank')
    onClose()
  }

  return (
    <Modal isOpen={true}>
      <ModalHeader tag='h3' className='p-2 d-flex justify-content-center'><FormattedMessage id='txt_pass_to_lesson' /></ModalHeader>
      <ModalBody>
        <FormattedMessage tagName='h5' id='txt_join_to_lesson' values={{ date }} />
      </ModalBody>
      <ModalFooter>
        <Button color='primary' className='apply' onClick={onOpenLink}><FormattedMessage id='btn_open_link' /></Button>
        <Button color='danger' className='apply' onClick={onClose}><FormattedMessage id='btn_cancel' /></Button>
      </ModalFooter>
    </Modal>
  )
}

export default LessonModdal